/* External dependencies */
import { Label } from 'atomize';
import React from 'react';

interface CheckboxProps {
  disabled?: boolean;
  isActive: boolean;
  label: React.ReactChild;
  onChange?: (item: any) => void;
}

export default function Checkbox(props: CheckboxProps) {
  const { disabled, isActive, label, onChange } = props;

  return (
    <Label
      border={{ t: '1px solid' }}
      borderColor="basic_border_color"
      m={{ t: '1rem' }}
      p={{ t: '1rem' }}
      align="center"
    >
      <input
        type="checkbox"
        checked={isActive}
        disabled={disabled}
        onChange={onChange}
        value="isCargoUnloadingByClient"
        style={{ height: '20px', width: '20px', marginRight: '1rem' }}
      />
      {label}
    </Label>
  );
}
