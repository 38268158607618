/* External dependencies */
import { Col, Div, Icon, Row, Text } from 'atomize';
import { FormattedMessage, navigate } from 'gatsby-plugin-react-intl';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

/* Local dependencies */
import formatMessage from '../../intl/formatMessage';
import { RootState } from '../../redux/store';
import PrimaryButton from '../buttons/PrimaryButton';
import Checkbox from '../checkBox/Checkbox';
import DateTime from '../common/DateTime';
import { getUserRole, setLocalStorage } from '../common/helpers';
import { UserRoles } from '../common/roles';
import { Consignee } from '../create-cargo/redux/actions';
import { Vehicle } from '../create-vehicle/redux/actions';
import UnloadDropdown from '../custom-dropdown/UnloadDropdown';
import UnloadingOptions from '../custom-dropdown/UnloadingOptions';
import FormField from '../form/FormField';
import ErrorPopup from '../popup/ErrorPopup';
import PrimaryPopup from '../popup/PrimaryPopup';
import SuccessPopup from '../popup/SuccessPopup';
import Search from '../search/Search';
import Table, { RowSelectionType } from '../tables/Table';
import Tabs from '../tabs/Tabs';
import { getVehicle } from '../vehicle-details/redux/actions';
import { Warehouse } from '../warehouses/types';
import { adminTabs, managerTabs } from './common/tab-filters';
import {
  listConsignees,
  resetSelectedConsignees,
  selectConsignee,
  selectPlaceForMovingCargo,
  selectVehicleForExtraction,
  selectWarehouseForExtraction,
  sortConsigneesBy,
} from './redux/actions';
import {
  changeCargoProductsName,
  onUnloadCargoByClient,
  resetError,
  resetUnloadCargo,
  unloadCargo,
} from './redux/cargo-unloading/actions';
import { UnloadCargoState } from './redux/cargo-unloading/reducer';
import { ConsigneesState } from './redux/reducer';
import { listVehiclesForExtraction } from './redux/vehicles-for-extraction/actions';
import { VehiclesForExtractionState } from './redux/vehicles-for-extraction/reducer';
import { listWarehousesForUnloading } from './redux/warehouse-for-unload/actions';
import { WarehousesForUnloadingState } from './redux/warehouse-for-unload/reducer';
import { DestinationForUnloading } from './types';

interface RouterProps {
  path?: string | string[];
}

export default function Consignees(path: RouterProps) {
  const findOrganization = formatMessage('findConsigneeAndVehicle');
  const ref = useRef(null);

  const [state, setState] = useState({
    showUnloadingOptions: false,
    showUnloadingDestinations: false,
  });
  const [isConfirmationPopupVisible, setConfirmationState] = useState(false);
  const [refContainer, setRefContainer] = useState(null);

  const dispatch = useDispatch();

  const {
    consignees,
    currentPage,
    filter,
    loading,
    searchString,
    selectedItems,
    total,
    vehicleForExtraction,
    warehouseForExtraction,
    cargoMovingTo,
  } = useSelector((state: RootState): ConsigneesState => state.consignees);

  const { loading: loadingOfVehiclesForExtraction, vehicles } = useSelector(
    (state: RootState): VehiclesForExtractionState =>
      state.vehiclesForExtraction,
  );

  const {
    loading: loadingOfWarehousesForUnloading,
    warehouses,
    error: wareHouseError,
  } = useSelector(
    (state: RootState): WarehousesForUnloadingState =>
      state.warehousesForUnloading,
  );

  const {
    error,
    isCargoUnloadByClient,
    cargoProductsName,
    isSuccess,
    loadingOfUnloadCargo,
  } = useSelector((state: RootState): UnloadCargoState => state.cargoUnload);

  const { currentUser } = useSelector((state: RootState) => state.login);

  const errorMessage =
    error &&
    (error.code ? <FormattedMessage id={error.code} /> : error.message);

  const isVehicleNotWarehouse =
    cargoMovingTo === DestinationForUnloading.VEHICLE;

  let unloadListName = '';
  let unloadNotFound = '';

  switch (cargoMovingTo) {
    case DestinationForUnloading.VEHICLE:
      unloadListName = 'selectAuto';
      unloadNotFound = 'noVehiclesForExtraction';
      break;

    case DestinationForUnloading.WAREHOUSE:
      unloadListName = 'selectWarehouse';
      unloadNotFound = 'thereAreNoWarehousesForExtraction';
      break;

    default:
      unloadListName = 'selectAuto';
      unloadNotFound = 'noVehiclesForExtraction';
      break;
  }

  function checkIfClickedOutside(e) {
    // If the Dropdown, UnloadingOptions, pop-up is open and the clicked target is not within theme,
    // then close the Dropdown and UnloadingOptions
    let showedPopUp =
      state.showUnloadingOptions || state.showUnloadingDestinations;

    let targetOutside = ref.current && !ref.current.contains(e.target);

    if (!ref.current) {
      ref.current = refContainer;
    }
    if (showedPopUp && targetOutside) {
      setState({
        ...state,
        showUnloadingOptions: false,
        showUnloadingDestinations: false,
      });
    }
  }

  useEffect(() => {
    document.addEventListener('mousedown', checkIfClickedOutside);

    return () => {
      document.removeEventListener('mousedown', checkIfClickedOutside);
    };
  }, [state.showUnloadingDestinations, state.showUnloadingOptions]);

  useEffect(() => {
    dispatch(listConsignees());

    setRefContainer(ref.current);

    return () => {
      dispatch(resetSelectedConsignees());
    };
  }, []);

  useEffect(() => {
    if (isSuccess) {
      setConfirmationState(false);
    }
  }, [isSuccess]);

  function onSearch(e) {
    dispatch(listConsignees(e.target.value, 1, filter));
  }

  function resetSearchField() {
    onSearch({
      target: { value: '' },
    });
  }

  function onPageChange(page) {
    dispatch(listConsignees(searchString, page, filter));
  }

  function onSelect(consignee: Consignee) {
    dispatch(selectConsignee(consignee));
  }

  const onConsigneesSort = () => dispatch(sortConsigneesBy('registrationDate'));

  function ConsigneeSearch() {
    return (
      <Search
        onChange={onSearch}
        onClear={resetSearchField}
        placeholder={findOrganization}
        value={searchString}
      />
    );
  }

  function onSelectUnloadingOption() {
    setState({
      ...state,
      showUnloadingOptions: true,
      showUnloadingDestinations: false,
    });
  }

  function showVehiclesForExtraction() {
    dispatch(selectPlaceForMovingCargo(DestinationForUnloading.VEHICLE));

    dispatch(listVehiclesForExtraction());

    setState({
      ...state,
      showUnloadingOptions: false,
      showUnloadingDestinations: true,
    });
  }

  function showWarehousesForExtraction() {
    dispatch(selectPlaceForMovingCargo(DestinationForUnloading.WAREHOUSE));

    dispatch(listWarehousesForUnloading());

    setState({
      ...state,
      showUnloadingOptions: false,
      showUnloadingDestinations: true,
    });
  }

  function openConfirmationPopup(selectedItem: Vehicle | Warehouse) {
    if (isVehicleNotWarehouse) {
      dispatch(selectVehicleForExtraction(selectedItem as Vehicle));
    } else {
      dispatch(selectWarehouseForExtraction(selectedItem as Warehouse));
    }

    setConfirmationState(true);
  }

  function closeConfirmationPopup() {
    // reset checkbox if you close confirmationPopup
    dispatch(onUnloadCargoByClient(false));

    setConfirmationState(false);
    setState({
      ...state,
      showUnloadingOptions: false,
      showUnloadingDestinations: false,
    });
  }

  function onSubmit() {
    if (isVehicleNotWarehouse) {
      dispatch(
        unloadCargo(selectedItems, {
          vehicleForExtraction,
          isCargoUnloadByClient,
        }),
      );
    } else {
      dispatch(
        unloadCargo(selectedItems, {
          warehouseForExtraction,
          cargoProductsName,
        }),
      );
    }
  }

  function handleOnChangeCheckbox(e) {
    dispatch(onUnloadCargoByClient(e.target.checked));
  }
  function handleChangeInput(e) {
    dispatch(changeCargoProductsName(e.target.value));
  }

  function onSuccessSubmit() {
    if (isVehicleNotWarehouse) {
      navigate('/vehicles/vehicle-details');
      dispatch(getVehicle(vehicleForExtraction.id));
    } else {
      setLocalStorage('consigneeId', selectedItems[0].id);
      navigate('/consignees/consignee-details');
    }
    dispatch(resetUnloadCargo());
  }

  const currentTab = JSON.stringify(filter);
  const isWarehouseTab = currentTab === managerTabs[2].value;

  function onFilterChange(e) {
    const filter = JSON.parse(e.target.value);

    dispatch(listConsignees('', 1, filter));
    dispatch(resetSelectedConsignees());
  }

  function onResetError() {
    dispatch(resetError());

    closeConfirmationPopup();
  }

  function renderConsigneeName({ id, name }: Consignee) {
    const onClickConsigneeName = () => {
      setLocalStorage('consigneeId', id);

      navigate('/consignees/consignee-details');
    };

    return (
      <p
        style={{ color: 'inherit', cursor: 'pointer' }}
        onClick={onClickConsigneeName}
      >
        {name}
      </p>
    );
  }

  const topBar = (
    <Text
      textColor="table_cell_text_color"
      textSize={{ xs: 'mobileTitle', md: 'title' }}
      textWeight="700"
    >
      <FormattedMessage id="Consignees" /> (
      {loading ? <Icon m="0 .62rem" name="Loading3" size="20px" /> : total})
    </Text>
  );

  const controlBar = (tabs) => (
    <Row p={{ y: '.5rem' }}>
      <Col size={{ xs: '12', lg: '6' }}>
        <Tabs
          inputName=""
          items={tabs}
          onClick={onFilterChange}
          value={currentTab}
        />
      </Col>
      <Col
        d="flex"
        justify={{ xs: 'start', lg: 'end' }}
        size={{ xs: '12', lg: '6' }}
      >
        <Row pos="relative" ref={ref}>
          <Col
            m={{ r: '24px' }}
            minW={{ xs: 'auto', sm: '332px' }}
            d="flex"
            justify="start"
          >
            <Div m={{ b: '1rem' }}>{ConsigneeSearch()}</Div>
          </Col>
          <Col d="flex" justify="end">
            <PrimaryButton
              icon="Minus"
              disabled={selectedItems ? !selectedItems.length || loading : null}
              onClick={onSelectUnloadingOption}
            >
              <FormattedMessage id={isWarehouseTab ? 'loadBtn' : 'unloadBtn'} />
            </PrimaryButton>
          </Col>
          {(state.showUnloadingOptions || state.showUnloadingDestinations) && (
            <Col pos="absolute" right="0" top="0" zIndex="100">
              {state.showUnloadingOptions && (
                <UnloadingOptions
                  isWarehouseTab={isWarehouseTab}
                  onClickVehicles={showVehiclesForExtraction}
                  onClickWarehouses={showWarehousesForExtraction}
                />
              )}
              {state.showUnloadingDestinations && (
                <UnloadDropdown
                  listName={<FormattedMessage id={`${unloadListName}`} />}
                  loading={
                    isVehicleNotWarehouse
                      ? loadingOfVehiclesForExtraction
                      : loadingOfWarehousesForUnloading
                  }
                  notFound={<FormattedMessage id={`${unloadNotFound}`} />}
                  onSelect={openConfirmationPopup}
                  items={isVehicleNotWarehouse ? vehicles : warehouses}
                  type={isVehicleNotWarehouse ? null : 'whareHouse'}
                />
              )}
            </Col>
          )}
        </Row>
      </Col>
    </Row>
  );

  const table = (
    <>
      <Table
        currentPage={currentPage}
        columns={getFields()}
        controlBar={getControlBar()}
        className="table"
        emptyMessage={<FormattedMessage id="noOrganizations" />}
        items={consignees.map((consignee) => ({
          ...consignee,
          cargoWeight: consignee.cargoWeight.toLocaleString(),
          name: renderConsigneeName(consignee),
          registrationDate: <DateTime date={consignee.registrationDate} />,
          slots: consignee.slots.toLocaleString(),
          warehouse: consignee?.warehouse?.name,
        }))}
        loading={loading}
        onPageChange={onPageChange}
        onSelect={onSelect}
        searchString={searchString}
        stickyCol={1}
        selectedItems={selectedItems}
        selectionType={RowSelectionType.SINGLE}
        topBar={topBar}
        totalItemsCount={total}
      />
      {isConfirmationPopupVisible &&
        selectedItems.length > 0 &&
        !isSuccess &&
        !error && (
          <Div>
            <PrimaryPopup
              title={
                <FormattedMessage
                  id={
                    isVehicleNotWarehouse
                      ? 'unloadToVehicleQuestion'
                      : 'unloadtoWarehouseQuestion'
                  }
                />
              }
              cancelBtnText={<FormattedMessage id="cancel" />}
              onClose={closeConfirmationPopup}
              btnText={<FormattedMessage id="unloadBtn" />}
              loading={loadingOfUnloadCargo}
              onClick={onSubmit}
              isSubmitDisabled={!cargoProductsName && !isVehicleNotWarehouse}
            >
              <Div borderColor="basic_border_color" w="100%">
                <Text>
                  {isVehicleNotWarehouse
                    ? vehicleForExtraction?.vehiclePlateNumber
                    : warehouseForExtraction?.name}
                </Text>
                {selectedItems.map((item, index) => (
                  <div key={index}>
                    <Text>
                      <FormattedMessage id="slots" />
                      {` - ${item.slots}`}
                    </Text>
                    <Text>
                      <FormattedMessage id="weightSyn" />
                      {` - ${item.cargoWeight}`}
                    </Text>
                  </div>
                ))}
                {isVehicleNotWarehouse && !isWarehouseTab && (
                  <Checkbox
                    isActive={isCargoUnloadByClient}
                    label={<FormattedMessage id="clientUnloadHimself" />}
                    onChange={handleOnChangeCheckbox}
                  />
                )}
                {!isVehicleNotWarehouse && (
                  <Row
                    style={{
                      whiteSpace: 'nowrap',
                    }}
                    border={{ t: '1px solid' }}
                    borderColor={'search_bg_color'}
                    p={{ t: '1.5rem' }}
                    m={{ t: '1rem' }}
                    maxW="300px"
                  >
                    <FormField
                      bg="input_filled_bg"
                      colSize={'12'}
                      label={<FormattedMessage id="products" />}
                      labelSize="caption"
                      inputValue={cargoProductsName}
                      inputType="text"
                      inputName="cargoProductsName"
                      required={true}
                      placeholder={formatMessage(
                        'unloadtoWarehousePlaceholder',
                      )}
                      tabIndex="1"
                      onChange={handleChangeInput}
                    />
                  </Row>
                )}
              </Div>
            </PrimaryPopup>
          </Div>
        )}
      {isSuccess && (
        <Div>
          <SuccessPopup
            onSubmit={onSuccessSubmit}
            submitText={<FormattedMessage id="close" />}
            title={<FormattedMessage id="unloadedToVehicle" />}
          >
            <Div w="100%">
              <Text>
                {isVehicleNotWarehouse
                  ? vehicleForExtraction?.vehiclePlateNumber
                  : warehouseForExtraction?.name}
              </Text>
              {selectedItems.map((item, index) => (
                <>
                  <Text key={index}>
                    <FormattedMessage id="slots" />
                    {` - ${item.slots}`}
                  </Text>
                  <Text>
                    <FormattedMessage id="weightSyn" />
                    {` - ${item.cargoWeight}`}
                  </Text>
                </>
              ))}
              {cargoProductsName && (
                <Text>
                  <FormattedMessage id="product" />
                  {` - ${cargoProductsName}`}
                </Text>
              )}
              {isCargoUnloadByClient && (
                <Checkbox
                  isActive={true}
                  label={<FormattedMessage id="clientUnloadHimself" />}
                  disabled={true}
                />
              )}
            </Div>
          </SuccessPopup>
        </Div>
      )}
      {error && (
        <Div>
          <ErrorPopup
            onSubmit={onResetError}
            submitText={<FormattedMessage id="close" />}
            title={<FormattedMessage id="errorOccurred" />}
          >
            {errorMessage}
          </ErrorPopup>
        </Div>
      )}
    </>
  );

  function getTable() {
    const userRole = getUserRole(currentUser);

    switch (userRole) {
      case UserRoles.BTS_ADMIN:
        return table;
      case UserRoles.BTS_MANAGER_OPERATOR:
        return table;
      default:
        throw new Error(`Unrecognized Cognito user group role "${userRole}".`);
    }
  }

  function getControlBar() {
    const userRole = getUserRole(currentUser);

    switch (userRole) {
      case UserRoles.BTS_ADMIN:
        return getAdminControlBar();
      case UserRoles.BTS_MANAGER_OPERATOR:
        return getManagerOperatorControlBar();
      default:
        throw new Error(`Unrecognized Cognito user group role "${userRole}".`);
    }
  }

  function getAdminControlBar() {
    return controlBar(adminTabs);
  }

  function getManagerOperatorControlBar() {
    return controlBar(managerTabs);
  }

  function getFields() {
    const userRole = getUserRole(currentUser);

    switch (userRole) {
      case UserRoles.BTS_ADMIN:
        return getManagerOperatorFields();
      case UserRoles.BTS_MANAGER_OPERATOR:
        return getManagerOperatorFields();
      default:
        throw new Error(`Unrecognized Cognito user group role "${userRole}".`);
    }
  }

  function getManagerOperatorFields() {
    return [
      {
        field: 'registrationDate',
        title: <FormattedMessage id="registrationDate" />,
      },
      {
        field: 'name',
        title: <FormattedMessage id="organization" />,
      },
      isWarehouseTab
        ? {
            field: 'warehouse',
            title: <FormattedMessage id="warehouse" />,
          }
        : {
            field: 'vehiclePlateNumber',
            title: <FormattedMessage id="Vehicle" />,
          },
      {
        field: 'cargoWeight',
        title: <FormattedMessage id="cargoWeight" />,
      },
      {
        field: 'slots',
        title: <FormattedMessage id="slots" />,
      },
    ];
  }

  return getTable();
}
