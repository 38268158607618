/* External dependencies */
import { Div, Dropdown, Icon, Label } from 'atomize';
import React, { useState } from 'react';

type listType = 'whareHouse' | null;

interface UnloadDropdownProps {
  listName: React.ReactChild;
  loading: boolean;
  onSelect: (item: any) => void;
  items: Array<any>;
  notFound: React.ReactChild;
  type?: listType;
}

export default function UnloadDropdown(props: UnloadDropdownProps) {
  const [isOpen, showDropdown] = useState(true);
  const { listName, loading, items, notFound, onSelect, type } = props;

  function openDropdown() {
    showDropdown(!isOpen);
  }

  const labelKey = type ? 'name' : 'vehiclePlateNumber';

  const menuList = (
    <Div maxH="75vh" overflow="auto" textWeight="600">
      {loading ||
        items.map((item, index) => (
          <Label
            border="1px solid"
            borderColor="basic_border_color"
            d="flex"
            textSize="15px"
            justify="space-between"
            p={{ y: '16px', x: '24px' }}
            key={index}
          >
            {type ? `${index + 1}. ${item[labelKey]}` : item[labelKey]}
            <input
              name="outGoingItem"
              onChange={() => onSelect(item)}
              type="radio"
              value={item[labelKey]}
            />
          </Label>
        ))}
    </Div>
  );

  const dropDownChild = () => {
    if (loading) {
      return <Icon name="Loading" size="25px" />;
    } else if (!loading && !items.length) {
      return notFound;
    } else {
      return listName;
    }
  };

  return (
    <Dropdown
      border={{
        t: '4px solid',
      }}
      borderColor="primary_color"
      focusBorderColor="primary_color"
      focusBg="#fff"
      justify="center"
      isOpen={isOpen}
      h="61px"
      onClick={openDropdown}
      menu={menuList}
      rounded={
        !isOpen
          ? { br: '5px', bl: '5px', tr: '5px', tl: '5px' }
          : { br: 'none', bl: 'none', tr: '5px', tl: '5px' }
      }
      textColor="dropdownTextColor"
      textWeight="700"
      w="202px"
      openSuffix={null}
      closeSuffix={null}
    >
      {dropDownChild()}
    </Dropdown>
  );
}
