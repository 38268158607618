/* External dependencies */
import React from 'react';

/* Local dependencies */
import CancelButton from '../buttons/CancelButton';
import PrimaryButton from '../buttons/PrimaryButton';
import Popup from './Popup';
import PopupActions from './PopupActions';
import PopupContent from './PopupContent';

interface PrimaryPopupProps {
  loading?: boolean;
  title: React.ReactChild;
  onClose: () => void;
  onClick: () => void;
  btnText: React.ReactChild;
  cancelBtnText: React.ReactChild;
  children?: React.ReactNode;
  className?: string;
  minWidth?: string;
  isSubmitDisabled?: boolean;
}

export default function PrimaryPopup({
  loading,
  title,
  onClose,
  onClick,
  btnText,
  cancelBtnText,
  children,
  className,
  minWidth = 'auto',
  isSubmitDisabled,
}: PrimaryPopupProps) {
  return (
    <Popup title={title} onClose={loading ? null : onClose} minWidth={minWidth}>
      {children && <PopupContent>{children}</PopupContent>}
      <PopupActions>
        <CancelButton
          disabled={loading}
          onClick={onClose}
          textColor="text_color"
        >
          {cancelBtnText}
        </CancelButton>
        <PrimaryButton disabled={loading || isSubmitDisabled} onClick={onClick}>
          {btnText}
        </PrimaryButton>
      </PopupActions>
    </Popup>
  );
}
