/* External dependencies */
import { Div } from 'atomize';
import { FormattedMessage } from 'gatsby-plugin-react-intl';
import React from 'react';

/* Local dependencies */
import './unloadingOptions.scss';

interface UnloadingOptionsProps {
  onClickVehicles: () => void;
  onClickWarehouses?: () => void;
  isWarehouseTab: boolean;
}

export default function UnloadingOptions(props: UnloadingOptionsProps) {
  const { onClickVehicles, onClickWarehouses, isWarehouseTab } = props;

  const divStyle = {
    alignItems: 'center',
    background: '#fff',
    textAlign: 'center',
    padding: '12px 0',
  };

  //These styles will be removed when added wareHouses
  const willBeRemoved = {
    alignItems: 'center',
    background: '#fff',
    textAlign: 'center',
    padding: '12px 0',
    pointerEvents: 'none',
    color: '#e1e4e8',
  };

  return (
    <Div
      d="block"
      w="202px"
      textColor="table_cell_text_color"
      textSize="13px"
      textWeight="600"
      cursor="pointer"
      rounder="10px"
    >
      <Div
        border="1px solid"
        borderColor="#EDF1F7"
        onClick={onClickWarehouses}
        style={divStyle}
        rounded={{ t: '4px' }}
        className="unload-option"
      >
        <FormattedMessage
          id={isWarehouseTab ? 'loadtoWarehouse' : 'unloadtoWarehouse'}
        />
      </Div>
      <Div
        border={{ t: 'none', b: '1px solid', x: '1px solid' }}
        borderColor="#EDF1F7"
        onClick={onClickVehicles}
        style={divStyle}
        rounded={{ b: '4px' }}
        className="unload-option"
      >
        <FormattedMessage
          id={isWarehouseTab ? 'loadToVehicle' : 'unloadToVehicle'}
        />
      </Div>
    </Div>
  );
}
