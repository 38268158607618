/* External dependencies */
import React from 'react';

/* Local dependencies */
import SuccessButton from '../buttons/SuccessButton';
import Popup from './Popup';
import PopupActions from './PopupActions';
import PopupContent from './PopupContent';
import './Popup.scss';

interface SuccessPopupProps {
  children: React.ReactNode;
  onSubmit: () => void;
  submitText: React.ReactChild;
  title: React.ReactChild;
}

export default function SuccessPopup({
  children,
  onSubmit,
  submitText,
  title,
}: SuccessPopupProps) {
  return (
    <Popup title={title}>
      <PopupContent>{children}</PopupContent>
      <PopupActions>
        <SuccessButton onClick={onSubmit}>{submitText}</SuccessButton>
      </PopupActions>
    </Popup>
  );
}
