import { ConsigneeStatus, DEFAULT_CONSIGNEES_STATUS } from '../redux/actions';

export const adminTabs = [
  {
    label: 'inVehicle',
    value: JSON.stringify(DEFAULT_CONSIGNEES_STATUS),
  },
  {
    label: 'onDepature',
    value: JSON.stringify({
      status: ConsigneeStatus.UNLOADED_TO_VEHICLE,
    }),
  },
  {
    label: 'inWarehouse',
    value: JSON.stringify({
      status: ConsigneeStatus.UNLOADED_TO_WAREHOUSE,
    }),
  },
  {
    label: 'archive',
    value: JSON.stringify({
      status: ConsigneeStatus.DEPARTED,
    }),
  },
];

export const managerTabs = [
  {
    label: 'inVehicle',
    value: JSON.stringify(DEFAULT_CONSIGNEES_STATUS),
  },
  {
    label: 'onDepature',
    value: JSON.stringify({
      status: ConsigneeStatus.UNLOADED_TO_VEHICLE,
    }),
  },
  {
    label: 'inWarehouse',
    value: JSON.stringify({
      status: ConsigneeStatus.UNLOADED_TO_WAREHOUSE,
    }),
  },
];
